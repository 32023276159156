@import '../../styles/utils';

.section {
  margin-bottom: 70px;

  @include onTablet {
    margin-bottom: 120px;
  }

  @include onDesktop {
    margin-bottom: 185px;
  }
}
