@import '../../styles/utils';

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 20px;

  height: 100%;

  @include onTablet {
    padding: 0 48px;
  }

  @include onDesktop {
    padding: 0 120px;
  }
}
