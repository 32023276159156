@import '../../styles/utils/mixins';
@import '../../styles/utils/extends';

.section {
  margin-bottom: 70px;

  @include onTablet {
    margin-bottom: 120px;
  }

  @include onDesktop {
    margin-bottom: 185px;
  }
}

.newsTitle {
  @extend %listPostTitle;

  width: 70%;
  height: 55px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto 67px;

  border: 3px solid $secondaryColor;
  border-radius: 60px;
  text-transform: uppercase;

  @include onTablet {
    max-width: 460px;
    min-width: 360px;
    width: 50%;
    height: 80px;

    margin-bottom: 87px;
  }

  @include onDesktop {
    width: 560px;
    height: 100px;

    margin-bottom: 117px;
  }
}
