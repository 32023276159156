@mixin onTablet {
  @media (min-width: 481px) and (max-width: 1024px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin onTabletAndDesktop {
  @media (min-width: 481px) {
    @content;
  }
}

@mixin onMobileAndTablet {
  @media (max-width: 1024px) {
    @content;
  }
}
