@import '../../../../styles/utils';

.item {
  position: relative;

  height: 100px;
  padding: 5px 10px;

  border: $secondaryColor 1px solid;
  border-radius: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: box-shadow $td ease;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }

  overflow: hidden;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @include onTabletAndDesktop {
    padding: 15px 25px;
  }

  &.loading {
    opacity: 0.5;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.selected {
  background-color: #eee;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  object-fit: cover;
  object-position: center;
  width: 100px;
  height: 80px;
  border-radius: 15px;

  margin-right: 15px;

  @include onTablet {
    width: 100px;
    height: 80px;
    margin-right: 20px;
  }

  @media (min-width: 481px) and (max-width: 660px) {
    display: none;
  }

  @include onDesktop {
    min-width: 300px;
    margin-right: 30px;
  }
}

.info {
  @extend %mainText;
  margin-right: 10px;
  min-width: 100px;

  @include onTablet {
    min-width: 250px;
  }
}

.title {
  font-size: 16px;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
  color: $secondaryColor;

  @include onTablet {
    font-size: 20px;
    line-height: 22px;
    max-height: 44px;
  }

  @include onDesktop {
    font-size: 32px;
    line-height: 34px;
    max-height: 68px;
  }

  &:hover {
    color: $primaryColor;
  }
}

.date {
  display: block;
  font-size: 14px;

  @include onTablet {
    font-size: 18px;
  }

  @include onDesktop {
    font-size: 20px;
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
  background-color: $secondaryColor;
  border-radius: 10px;

  @include onTablet {
    width: 30px;
    height: 30px;
  }

  @include onDesktop {
    width: 40px;
    height: 40px;
  }

  &:hover {
    background-color: $primaryColor;
  }
}

.closeImage {
  min-width: 15px;
  height: 15px;
  object-position: center;
  object-fit: contain;

  @include onTablet {
    width: 28px;
    height: 28px;
  }

  @include onDesktop {
    width: 40px;
    height: 40px;
  }
}

