body,
h1,
h2,
h3,
p,
ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  outline: none;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  background-color: unset;
}

input,
textarea {
  outline: none;
  border: 0;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
}
