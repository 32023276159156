@import '../../styles/utils';

.header {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.link {
  display: flex;
  width: max-content;
  cursor: pointer;
}

.logo {
  width: 270px;
  height: 45px;
  
  @include onTablet {
    width: 380px;
    height: 50px;
  }

  @include onDesktop {
    width: 580px;
    height: 90px;
  }
}
