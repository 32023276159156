html {
  font-family: 'DniproCity', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.react-loading-skeleton {
  line-height: unset !important;
  height: 100%;
}

