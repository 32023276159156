@import '../../styles/utils';

.button {
  @extend %buttonText;
  height: 100%;
  width: 100%;

  background-color: #eee;
  border-radius: 10px;

  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-color: #ddd;
  }
}
