@import '../../styles/utils';

.authPanel {
  height: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @include onTablet {
    height: 45px;
  }

  @include onDesktop {
    height: 55px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &.noUser {
    justify-content: right;
  }
}

.info {
  display: flex;
  align-items: center;
  position: relative;

  .username {
    position: absolute;
    right: calc(100% + 15px);
    width: max-content;
    display: block;
    font-size: 14px;

    @include onTablet {
      font-size: 16px;
    }

    @include onDesktop {
      font-size: 20px;
    }
  }

  @include onDesktop {
    .username {
      transform: translateY(-150%);
      opacity: 0;

      transition:
        opacity $td ease,
        transform $td ease;
    }
  
    &:hover {
      .username {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.icon {
  width: 25px;
  height: 25px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @include onTablet {
    width: 28px;
    height: 28px;
  }

  @include onDesktop {
    width: 32px;
    height: 32px;
  }
}

.logout {
  background-image: url('../../../public/icons/logout.svg');
  transform: scaleX(-1);
}

.profile {
  background-image: url('../../../public/icons/profile.svg');
}
