@import '../../../../styles/utils';

.topInfo {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
}

.title {
  @extend %postTitle;
  margin-right: 20px;
}

.skip {
  @extend %mainText;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  text-decoration: underline;
  color: $secondaryColor;
  line-height: 150%;

  &:hover {
    color: $primaryColor;
  }

  @include onTablet {
    font-size: 16px;
  }

  @include onDesktop {
    font-size: 18px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 20px 0;
}

.container {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.field {
  @extend %mainText;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px $secondaryColor solid;
  resize: none;
  border-radius: 15px;
  overflow: hidden;

  &::placeholder {
    color: $secondaryColor;
  }
}

.file {
  border: none;
}

.validation {
  @extend %mainText;
  display: flex;

  font-size: 14px;
  line-height: 14px;
  color: red;

  &.hidden {
    visibility: hidden;
  }
}

.button {
  height: 40px;
  margin-bottom: 20px;

  @include onTabletAndDesktop {
    height: 60px;
  }
}
