@import '../../../../styles/utils';

.title {
  @extend %postTitle;
  margin-bottom: 20px;
}

.date {
  @extend %postDate;
  display: block;
  margin-bottom: 10px;
}

.image {
  display: block;
  width: 60%;
  margin: 0 auto;

  margin-bottom: 35px;

  border-radius: 20px;

  @include onTablet {
    margin-bottom: 50px;
    border-radius: 35px;
  }

  @include onDesktop {
    margin-bottom: 70px;
    border-radius: 50px;
  }
}

.paragraph {
  &:not(:last-child) {
    margin-bottom: 35px;
  }
}
