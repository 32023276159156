@import './vars';
@import './mixins';

%mainText {
  font-size: 20px;
  line-height: 28px;
  color: $primaryColor;

  @include onTablet {
    font-size: 25px;
    line-height: 33px;
  }

  @include onDesktop {
    font-size: 30px;
    line-height: 40px;
  }
}

%postTitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 37px;
  color: $primaryColor;

  @include onTablet {
    font-size: 45px;
    line-height: 47px;
  }

  @include onDesktop {
    font-size: 55px;
    line-height: 52px;
  }
}

%postDate {
  font-size: 20px;
  line-height: 32px;
  color: $secondaryColor;

  @include onTablet {
    font-size: 25px;
    line-height: 42px;
  }

  @include onDesktop {
    font-size: 30px;
    line-height: 52px;
  }
}

%listPostTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: $secondaryColor;

  @include onTablet {
    font-size: 24px;
    line-height: 26px;
  }

  @include onDesktop {
    font-size: 36px;
    line-height: 38px;
  }
}

%footerText {
  font-size: 24px;
  line-height: 28px;
  color: $primaryColor;
}

%buttonText {
  @extend %mainText;

  font-size: 20px;

  @include onTablet {
    font-size: 24px;
  }

  @include onDesktop {
    font-size: 28px;
  }
}

%circleSkeleton {
  border-radius: 15px;
  overflow: hidden;
}
