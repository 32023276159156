@import '../../styles/utils';

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  @extend %postTitle;
  margin-bottom: 20px;
  align-self: flex-start;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 90%;

  @include onTablet {
    width: 70%;
  }

  @include onDesktop {
    width: 50%;
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.button {
  height: 40px;
  margin-bottom: 20px;

  @include onTabletAndDesktop {
    height: 60px;
  }
}

.validation {
  font-size: 14px;
  line-height: 14px;
  color: red;
}

