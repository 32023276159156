@import '../../../../styles/utils';

.mainArea {
  @extend %mainText;
  box-sizing: border-box;

  height: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  margin-bottom: 5px;
  border: 1px $secondaryColor dashed;

  border-radius: 15px;
  font-size: 18px;
  text-align: center;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: 5px;
}

.info {
  display: flex;
  justify-content: space-between;

  margin-bottom: 15px;
  padding: 0 5px;
}

.image {
  display: block;
  margin: 0 auto;
  max-width: 70%;
  border-radius: 15px;

  @include onMobileAndTablet {
    max-width: 100%;
  }
}
