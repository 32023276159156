@import '../../styles/utils/mixins';
@import '../../styles/utils/extends';

.wrapper {
  @extend %mainText;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  scroll-behavior: smooth;
}

.header {
  margin-bottom: 40px;

  @include onTablet {
    padding: 30px 0;
    margin-bottom: 70px;
  }

  @include onDesktop {
    padding: 50px 0;
    margin-bottom: 110px;
  }
}

.main {
  flex-grow: 1;
}

.footer {
  height: 100px;

  @include onTablet {
    height: 120px;
  }

  @include onDesktop {
    height: 140px;
  }
}
