@import '../../../../styles/utils';

.topInfo {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  @include onTablet {
    margin-top: 15px;
  }
}

.title,
.titleLow {
  @extend %circleSkeleton;
  @extend %postTitle;
  width: 200px;
  height: 32px;

  @include onTablet {
    width: 250px;
    height: 45px;
  }

  @media (min-width: 630px) {
    width: 420px;
    height: 50px;
  }

  @include onDesktop {
    width: 512px;
    height: 52px;
  }
}

.titleLow {
  width: 130px;
  margin-top: 5px;

  @media (min-width: 630px) {
    display: none;
  }

  @include onTablet {
    margin-top: 10px;
  }
}

.skip {
  @extend %circleSkeleton;
  align-self: flex-end;
  width: 80px;
  font-size: 14px;

  @include onTablet {
    font-size: 16px;
  }

  @include onDesktop {
    width: 85px;
    height: 25px;
  }
}

.form {
  margin: 25px 0;
}

.titleInput {
  @extend %circleSkeleton;
  margin-bottom: 20px;
  height: 50px;

  @include onTablet {
    height: 55px;
  }

  @include onDesktop {
    height: 62px;
    margin-bottom: 25px;
  }
}

.textInput {
  @extend %circleSkeleton;
  margin-bottom: 30px;
  height: 190px;

  @include onTablet {
    height: 220px;
    margin-bottom: 55px;
  }

  @include onDesktop {
    height: 262px;
    margin-bottom: 55px;
  }
}

.fileInput {
  @extend %circleSkeleton;
  display: block;
  width: 80%;
  margin: 40px auto 20px;
  height: 250px;

  @include onTablet {
    height: 390px;
  }

  @media (max-width: 600px) {
    height: 270px;
  }

  @include onDesktop {
    height: 420px;
  }
}

.button {
  @extend %circleSkeleton;
  height: 40px;

  @include onTabletAndDesktop {
    height: 60px;
  }
}
