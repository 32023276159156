@import '../../styles/utils';

.field {
  @extend %mainText;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px $secondaryColor solid;
  resize: none;
  border-radius: 15px;
  overflow: hidden;

  &::placeholder {
    color: $secondaryColor;
  }
}

.validation {
  font-size: 14px;
  line-height: 14px;
  color: red;
}
