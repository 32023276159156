@import '../../styles/utils';

.footer {
  @extend %footerText;
  width: 100%;
  height: 100%;

  font-size: 24px;
  background-color: $footerColor;
}

.content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include onTabletAndDesktop {
    justify-content: space-between;
  }
}

.leftPanel,
.rightPanel {
  display: flex;
  align-items: center;
}

.leftPanel {
  display: none;
  
  @include onTabletAndDesktop {
    display: flex;
  }
}

.iconLink {
  &:not(:last-child) {
    margin-right: 60px;
  }

  @include onTablet {
    &:not(:last-child) {
      margin-right: 45px;
    }
  }
}

.icon {
  width: 40px;
  height: 40px;

  @include onMobileAndTablet {
    width: 30px;
    height: 30px;
  }
}

.label {
  display: none;

  @media (min-width: 1620px) {
    display: block;
    margin-right: 40px;
  }
}

.rights {
  @include onTablet {
    font-size: 18px;
  }

  @media (max-width: 560px) {
    display: none;
  }
}

.logoLink {
  margin-right: 40px;

  @media (max-width: 660px) {
    margin-right: 15px;
  }
}

.logo {
  height: 40px;

  @include onMobileAndTablet {
    width: 40px;
    height: 40px;
  }
}
