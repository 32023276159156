@import '../../../../styles/utils';

.item {
  display: flex;
  width: 100%;
  height: 100%;

  &:not(:last-child) {
    margin-bottom: 63px;
  }
}

.image {
  box-sizing: border-box;
  width: 130px;
  height: 130px;
  margin-right: 20px;

  object-fit: cover;
  object-position: center;
  border-radius: 20px;

  @include onTablet {
    width: 200px;
    height: 200px;
    margin-right: 40px;
    border-radius: 35px;
  }

  @include onDesktop {
    height: 300px;
    min-width: 50%;
    margin-right: 50px;
    border-radius: 50px;
  }
}

.title {
  @extend %listPostTitle;
  display: block;
  margin-bottom: 23px;
  max-height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;

  @include onTablet {
    max-height: 130px;
  }

  @include onDesktop {
    max-height: 190px;
  }

  &:hover {
    color: $primaryColor;
  }
}

.date {
  @extend %postDate;
  display: block;
}
